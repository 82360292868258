import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core compone

import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";

import styles from "assets/jss/material-kit-react/views/RegisterPage.js";
import CircularProgress from "@material-ui/core/CircularProgress";

import image from "assets/img/pinehurst.jpeg";
import { Auth } from "aws-amplify";

const useStyles = makeStyles(styles);

export default function ConfirmCodePage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const history = useHistory();

  const { ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [error, seterror] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);

  const isValid = useCallback(() => code !== "", [code]);

  function handleSubmit(event) {
    event.preventDefault();

    confirmSignUp();
  }
  async function confirmSignUp() {
    try {
      setLoading(true);
      await Auth.confirmSignUp(props.location.state.username, code);

      localStorage.setItem("userinfo", props.location.state.userdata);
      setLoading(false);
      history.push("./profile-page");
    } catch (err) {
      setErrorOpen(true);
      seterror(err.message);
      setLoading(false);
    }
  }

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Claim System"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form
                  className={classes.form}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <CardHeader color="golf" className={classes.cardHeader}>
                    <h4>Confirm Code</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Confirm Code"
                      id="confirmcode"
                      formControlProps={{
                        onChange: (e) => setCode(e.target.value),
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      disabled={!isValid() || loading}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      <div>
                        {loading ? <CircularProgress size={"18px"} /> : ""}
                        Confirm
                      </div>
                    </Button>
                  </CardFooter>
                </form>
              </Card>
              {error ? (
                <Snackbar
                  variant="error"
                  key={error}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  open={errorOpen}
                  onClose={() => setErrorOpen(false)}
                  autoHideDuration={3000}
                >
                  <SnackbarContent
                    className={classes.error}
                    message={
                      <div>
                        <span style={{ marginRight: "8px" }}>
                          <ErrorIcon fontSize="large" color="error" />
                        </span>
                        <span> {error} </span>
                      </div>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="close"
                        onClick={() => setErrorOpen(false)}
                      >
                        <CloseIcon color="error" />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
              ) : null}
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
