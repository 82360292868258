import React from "react";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import amplifyconfig from "assets/jss/configure";
import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components";
import LandingPage from "views/LandingPage/LandingPage";
import ProfilePage from "views/ProfilePage/ProfilePage";
import LoginPage from "views/LoginPage/LoginPage";
import RegisterPage from "views/RegisterPage/RegisterPage";
import PasswordSetPage from "views/Password/PasswordSetPage";
import ConfirmCodePage from "views/Password/ConfirmCodePage";
import EmailConfirmPage from "views/Password/EmailConfirmPage";
import ResetPage from "views/Password/ResetPage";

import ProtectedRoute from "./ProtectedRoute";

var hist = createBrowserHistory();
const App = document.getElementById("root");
Amplify.configure(amplifyconfig);

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <ProtectedRoute path="/landing-page" component={LandingPage} />
      <ProtectedRoute path="/profile-page" component={ProfilePage} />
      <ProtectedRoute path="/components" component={Components} />
      <ProtectedRoute path="/passwordchange" component={PasswordSetPage} />

      <Route path="/resetconfirm" component={ResetPage} />
      <Route path="/emailconfirm" component={EmailConfirmPage} />
      <Route path="/login" component={LoginPage} />
      <Route exact={true} path="/register" component={RegisterPage} />
      <Route exact={true} path="/confirmcode" component={ConfirmCodePage} />
      <Redirect to="/login" />
    </Switch>
  </Router>,
  App
);

export default withAuthenticator(App);
