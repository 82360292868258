import { container } from "assets/jss/material-kit-react.js";
import { red } from "@material-ui/core/colors";
import { Autorenew } from "@material-ui/icons";

const signupPageStyle = theme =>( {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: "#FFFFFF",
    paddingBottom: "200px"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: ""
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%"
    }
  },
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px"
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
    display: "block"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: "#495057"
  },
  error: {
    border: "1.2px solid ${red[900]}",
    background: "rgba(169,198,217,0.29457423)",
    color: red[900],
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Raleway, sans-serif",
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(3)
  },
  strengthBar: {
    display: "flex",
    width: "100%",
    marginBottom: "4px",
    marginTop: "8px",

    "& > div": {
      flexGrow: 1,
      height: "4px",
      marginRight: "4px",
      borderRadius: "4px",
      backgroundColor: "#bde2fb",
      "&:last-child": {
        marginRight: 0
      }
    }
  },

  weak: {
    "& > div:nth-child(1)": {
      backgroundColor: "#a02308"
    }
  },
  good: {
    "& > div:nth-child(1)": {
      backgroundColor: "#fbb034"
    },
    "& > div:nth-child(2)": {
      backgroundColor: "#fbb034"
    }
  },
  strong: {
    "& > div:nth-child(1)": {
      backgroundColor: "#4f8406"
    },
    "& > div:nth-child(2)": {
      backgroundColor: "#4f8406"
    },
    "& > div:nth-child(3)": {
      backgroundColor: "#4f8406"
    }
  },
  perfect: {
    "& > div:nth-child(1)": {
      backgroundColor: "#248cd1"
    },
    "& > div:nth-child(2)": {
      backgroundColor: "#248cd1"
    },
    "& > div:nth-child(3)": {
      backgroundColor: "#248cd1"
    },
    "& > div:nth-child(4)": {
      backgroundColor: "#248cd1"
    }
  },
  strengthLabel: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "#131624",
    textTransform: "capitalize"    
  }

});

export default signupPageStyle
