import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(true);

  const checkAuthentication = () => {
    try {
      setLoading(true);

      const user = localStorage.getItem("userinfo");

      if (user !== null) {
        setRedirect(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            redirect == false ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      )}
    </>
  );
};

export default ProtectedRoute;
