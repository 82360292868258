import Amplify, { Auth } from "aws-amplify";

const amplifyconfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "us-east-2",
    identifyPoolId: "us-east-2:e6eb37fb-51b5-4c0d-8a3f-855acd25aa04",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-2_lViDyIC7k",
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "5fjeb778hmodvjl463pi8nhd31",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: ".break80.net",
    //   // OPTIONAL - Cookie path
    //   path: "/",
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: "strict" | "lax",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //authenticationFlowType: 'USER_PASSWORD_AUTH',
    authenticationFlowType: "USER_SRP_AUTH",

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "auth.break80.net",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export default amplifyconfig;
