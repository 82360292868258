import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core compone

import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-kit-react/views/RegisterPage.js";

import image from "assets/img/pinehurst.jpeg";
import { Auth } from "aws-amplify";

const useStyles = makeStyles(styles);

export default function PasswordSetPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const history = useHistory();
  const { ...rest } = props;

  const [loading, setLoading] = useState(false);
  const [oldpass, setOldPassword] = useState("");
  const [newpass, setNewPassword] = useState("");
  const [confirmpass, setConfirmPassword] = useState("");
  const [error, seterror] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passStrengthLabel, setStrengthLabel] = useState("");
  const [passwordClassChange, setPasswordClassChange] = useState(
    classes.strengthBar
  );

  const passwordMatch = () => confirmpass === newpass;
  const isValid = useCallback(() => {
    if (oldpass === "") {
      return false;
    }

    if (newpass === "") {
      return false;
    }

    if (confirmpass === "") {
      return false;
    }

    if (passwordStrength < 4) {
      return false;
    }

    return true;
  }, [oldpass, passwordStrength, confirmpass]);

  const strengthChecker = () => {
    // We then change the badge's color and text based on the password strength
    let strongPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    let mediumPassword = new RegExp(
      "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
    );

    if (strongPassword.test(newpass)) {
      setPasswordStrength(4);
      setStrengthLabel("strong");
      setPasswordClassChange(classes.perfect);
    } else if (mediumPassword.test(newpass)) {
      setPasswordStrength(3);
      setStrengthLabel("normal");
      setPasswordClassChange(classes.strong);
    } else if (newpass !== "" && newpass.length > 6) {
      setPasswordStrength(2);
      setStrengthLabel("weak");
      setPasswordClassChange(classes.good);
    } else if (newpass !== "") {
      setPasswordStrength(1);
      setStrengthLabel("too short");
      setPasswordClassChange(classes.weak);
    } else {
      setPasswordStrength(0);
      setStrengthLabel("");
      setPasswordClassChange(classes.strengthBar);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (!passwordMatch()) {
      setErrorOpen(true);
      seterror("Confirm password should be matched with password.");
    } else {
      changePassword();
    }
  }
  const changePassword = async () => {
    try {
      setLoading(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldpass, newpass);
      setLoading(false);
      history.push("/profile-page");
    } catch (err) {
      setErrorOpen(true);
      seterror(err.message);
    }
  };

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Claim System"
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <CardHeader color="golf" className={classes.cardHeader}>
                    <h4>Change Password</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Old Password"
                      id="oldpass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e) => setOldPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      labelText="New Password"
                      id="newpass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e) => setNewPassword(e.target.value),
                        onKeyUp: () => strengthChecker(),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />

                    <div className="password-strength">
                      <div
                        className={[
                          classes.strengthBar,
                          passwordClassChange,
                        ].join(" ")}
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className={classes.strengthLabel}>
                        {passStrengthLabel}
                      </div>
                    </div>

                    <CustomInput
                      labelText="Confirm Password"
                      id="confirmpass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e) => setConfirmPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      disabled={!isValid() || loading}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      <div>
                        {loading ? <CircularProgress size={"18px"} /> : ""}
                        Change Password
                      </div>
                    </Button>
                  </CardFooter>
                </form>
              </Card>
              {error ? (
                <Snackbar
                  variant="error"
                  key={error}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  open={errorOpen}
                  onClose={() => setErrorOpen(false)}
                  autoHideDuration={3000}
                >
                  <SnackbarContent
                    className={classes.error}
                    message={
                      <div>
                        <span style={{ marginRight: "8px" }}>
                          <ErrorIcon fontSize="large" color="error" />
                        </span>
                        <span> {error} </span>
                      </div>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="close"
                        onClick={(e) => {
                          setErrorOpen(false);
                        }}
                      >
                        <CloseIcon color="error" />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
              ) : null}
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
