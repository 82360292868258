import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core compone

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import styles from "assets/jss/material-kit-react/views/RegisterPage.js";

import image from "assets/img/pinehurst.jpeg";
import { Auth, Hub } from "aws-amplify";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const history = useHistory();

  const { ...rest } = props;
  const state = { button: "Login" };

  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPassword] = useState("");

  const checkLogin = () => {
    try {
      setLoading(true);
      const user = localStorage.getItem("userinfo");

      if (user !== null) {
        history.push("/profile-page");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const isValid = useCallback(() => email !== "" && pass !== "");

  function handleSubmit(event) {
    event.preventDefault();
    if (state.button == "register") {
      signUp();
    } else if (state.button == "Login") {
      signIn();
    }
  }

  const signIn = async () => {
    try {
      setLoading(true);
      const user = await Auth.signIn(email, pass);
      localStorage.setItem("userinfo", user.userDataKey);
      setLoading(false);
      history.push("./profile-page");
    } catch (err) {
      setLoading(false);
      setErrorOpen(true);
      if (typeof err.message == "undefined") {
        seterror(err);
      } else {
        seterror(err.message);
      }
    }
  };

  const signUp = async () => history.push("/register");

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Claim System"
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <CardHeader color="sky" className={classes.cardHeader}>
                    <h4>Social Login</h4>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-twitter"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-facebook"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Username or Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e) => setPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <Link
                      component="a"
                      onClick={() => history.push("/emailconfirm")}
                    >
                      Forgot Password
                    </Link>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      disabled={!isValid() || loading}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      <div>
                        {loading ? <CircularProgress size={"18px"} /> : ""}
                        Login
                      </div>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        state.button = "register";
                      }}
                      fullWidth
                    >
                      Register
                    </Button>
                  </CardFooter>
                </form>
              </Card>
              {error ? (
                <Snackbar
                  variant="error"
                  key={error}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  open={errorOpen}
                  onClose={() => setErrorOpen(false)}
                  autoHideDuration={3000}
                >
                  <SnackbarContent
                    className={classes.error}
                    message={
                      <div>
                        <span style={{ marginRight: "8px" }}>
                          <ErrorIcon fontSize="large" color="error" />
                        </span>
                        <span> {error} </span>
                      </div>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="close"
                        onClick={() => setErrorOpen(false)}
                      >
                        <CloseIcon color="error" />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
              ) : null}
            </GridItem>
          </GridContainer>
        </div>

        <Footer whiteFont />
      </div>
    </div>
  );
}
