import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core compone

import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-kit-react/views/RegisterPage.js";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

import image from "assets/img/pinehurst.jpeg";
import { Auth } from "aws-amplify";

const useStyles = makeStyles(styles);

export default function EmailConfirmPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const history = useHistory();

  const { ...rest } = props;
  const [email, setEmail] = useState("");
  const [error, seterror] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const isValid = useCallback(() => email !== "");

  function handleSubmit(event) {
    event.preventDefault();
    confirmEmail();
  }
  const confirmEmail = async () => {
    try {
      const user = await Auth.forgotPassword(email);
      history.push("/resetconfirm", {
        useremail: email,
        userdata: user.userDataKey,
      });
    } catch (err) {
      setErrorOpen(true);
      seterror(err.message);
    }
  };

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Claim System"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form
                  className={classes.form}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <CardHeader color="golf" className={classes.cardHeader}>
                    <h4>Email Confirm</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Confirm Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (e) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      disabled={!isValid()}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Confirm Email
                    </Button>
                  </CardFooter>
                </form>
              </Card>
              {error ? (
                <Snackbar
                  variant="error"
                  key={error}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  open={errorOpen}
                  onClose={() => setErrorOpen(false)}
                  autoHideDuration={3000}
                >
                  <SnackbarContent
                    className={classes.error}
                    message={
                      <div>
                        <span style={{ marginRight: "8px" }}>
                          <ErrorIcon fontSize="large" color="error" />
                        </span>
                        <span> {error} </span>
                      </div>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="close"
                        onClick={() => setErrorOpen(false)}
                      >
                        <CloseIcon color="error" />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
              ) : null}
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
